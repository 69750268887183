import request from '@/utils/request'


// 查询运营区域计费列表
export function listAreaCharge(query) {
  return request({
    url: '/user/operating-area-charges/list',
    method: 'get',
    params: query
  })
}

// 查询运营区域计费分页
export function pageAreaCharge(query) {
  return request({
    url: '/user/operating-area-charges/page',
    method: 'get',
    params: query
  })
}

// 查询运营区域计费详细
export function getAreaCharge(data) {
  return request({
    url: '/user/operating-area-charges/detail',
    method: 'get',
    params: data
  })
}

// 新增运营区域计费
export function addAreaCharge(data) {
  return request({
    url: '/user/operating-area-charges/add',
    method: 'post',
    data: data
  })
}

// 修改运营区域计费
export function updateAreaCharge(data) {
  return request({
    url: '/user/operating-area-charges/edit',
    method: 'post',
    data: data
  })
}

// 删除运营区域计费
export function delAreaCharge(data) {
  return request({
    url: '/user/operating-area-charges/delete',
    method: 'post',
    data: data
  })
}

export function changeIsOpen(data) {
  return request({
    url: '/user/operating-area-charges/changeIsOpen',
    method: 'post',
    data: data
  })
}
