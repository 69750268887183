<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="运营地点" prop="areaName" >
        <a-input v-model="form.areaName" placeholder="请输入运营地点" />
      </a-form-model-item>
      <a-form-model-item label="起步价($)" prop="startPrice" >
        <a-input-number v-model="form.startPrice" placeholder="请输入起步价" :min='0.01' :max='9999999'  style='width: 100%'/>
      </a-form-model-item>
      <a-form-model-item label="免费里程" prop="startPriceMileage" >
        <a-input-number v-model="form.startPriceMileage" placeholder="请输入起步价免费里程" :min='0.01' :max='9999999'  style='width: 100%'/>
      </a-form-model-item>
      <a-form-model-item label="阶梯里程价" prop="mileagePriceStep" >

        <a-button type="primary" style='border-radius: 20px'  @click="changeFields">
          <a-icon type="plus" />新增
        </a-button>
        <table class="table"  style='width: 100%'>

          <thead>
          <tr>
<!--            <th style="text-align: center;">序号</th>-->
            <th>起始里程</th>
            <th>结束里程</th>
            <th>单价($)</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(data, index) in fields" :key="index">
<!--            <td style="text-align: center;">-->
<!--              {{ index + 1 }}-->
<!--            </td>-->
            <td>{{data.startMileage}}</td>
            <td>{{data.endMileage}}</td>
            <td>{{data.price}}</td>
            <td>
              <a @click='removeField(data,index)'>删除</a>
            </td>
          </tr>
          </tbody>
        </table>

<!--        <a-input v-model="form.mileagePriceStep" placeholder="请输入内容" type="textarea" allow-clear />-->
      </a-form-model-item>
      <a-form-model-item label="计时费($)" prop="timeFee" >
        <a-input-number v-model="form.timeFee" placeholder="请输入计时费" :min='0.01' :max='9999999'  style='width: 100%'/>
      </a-form-model-item>
      <a-form-model-item label="过时等待费($)" prop="waitFee" >
        <a-input-number v-model="form.waitFee" placeholder="请输入过时等待费" :min='0.01' :max='9999999'  style='width: 100%'/>
      </a-form-model-item>
      <a-form-model-item label="远途费里程($)" prop="distanceFeeMileage" >
        <a-input-number v-model="form.distanceFeeMileage" placeholder="请输入远途费里程" :min='1' :max='9999999'  style='width: 100%'/>
        <p style='margin: 0;color: red'>超过多少里程计算远途费</p>
      </a-form-model-item>
      <a-form-model-item label="远途费($)" prop="distanceFee" >
        <a-input-number v-model="form.distanceFee" placeholder="请输入过时等待费" :min='0.01' :max='9999999'  style='width: 100%'/>
      </a-form-model-item>
      <a-form-model-item label="夜间加价百分比(%)" prop="nightPriceIncr" >
        <a-input-number :min='0.01' :max='9999999' v-model="form.nightPriceIncr" suffix="%" placeholder="请输入夜间加价百分比" style='width: 100%' />
      </a-form-model-item>
      <a-form-model-item label="取消费用" prop="cancelPrice" >

        <a-input-number :min='0.01' :max='9999999'  v-model="form.cancelPrice" placeholder="请输入取消费用" style='width: 100%' />
      </a-form-model-item>
      <a-form-model-item label="预定费用" prop="bookPrice" >
        <a-input-number v-model="form.bookPrice" placeholder="请输入预定费用" :min='0.01' :max='9999999'  style='width: 100%'/>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <FieldsForm ref="fieldsForm"  @ok='addFields'/>
  </a-drawer>
</template>

<script>
import { getAreaCharge, addAreaCharge, updateAreaCharge } from '@/api/areaCharge/areaCharge'
import FieldsForm from '@/views/areaCharge/modules/FieldsForm.vue'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    FieldsForm
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      fields: [ { startMileage:1,endMileage:2,price:2 }   ],

      // 表单参数
      form: {
        id: null,

        areaName: null,

        startPrice: null,

        startPriceMileage: null,

        mileagePriceStep: null,

        timeFee: null,

        waitFee: null,

        distanceFeeMileage: null,

        distanceFee: null,

        nightPriceIncr: null,

        cancelPrice: null,

        bookPrice: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        areaName: [
          {required: true, message: '运营地点不能为空', trigger: 'blur'}
        ],

        startPrice: [
          {required: true, message: '起步价不能为空', trigger: 'blur'}
        ],
        startPriceMileage: [
          {required: true, message: '免费里程不能为空', trigger: 'blur'}
        ],
        mileagePriceStep: [
          {required: true, message: '阶梯里程价不能为空', trigger: 'blur'}
        ],
        timeFee: [
          {required: true, message: '计时费不能为空', trigger: 'blur'}
        ],
        waitFee: [
          {required: true, message: '过时等待费不能为空', trigger: 'blur'}
        ],
        cancelPrice: [
          {required: true, message: '取消费用不能为空', trigger: 'blur'}
        ],
        bookPrice: [
          {required: true, message: '预定费用不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    changeFields() {
      this.$refs['fieldsForm'].openModal(this.form.id, this.fields[this.fields.length], this.fields.length)
    },
    addFields(field){
      console.log("name========",field)
      this.fields.push(field)
    },

    removeField(field,index){
      this.fields.splice(index, 1)
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fields = []
      this.form = {
        id: null,
        areaName: null,
        startPrice: null,
        startPriceMileage: null,
        mileagePriceStep: null,
        timeFee: null,
        waitFee: null,
        distanceFeeMileage: null,
        distanceFee: null,
        nightPriceIncr: null,
        cancelPrice: null,
        bookPrice: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAreaCharge({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        if(this.form.mileagePriceStep){
          this.fields = JSON.parse(this.form.mileagePriceStep)
        }
      })

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.mileagePriceStep = JSON.stringify(this.fields)
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAreaCharge(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAreaCharge(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.table {
  thead {
    tr {
      th {
        padding: 6px 8px;
        color: #000;
        font-weight: 500;
        text-align: left;
        background: #fafafa;
        border: 1px solid #e8e8e8;
        transition: background 0.3s ease
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 12px 8px;
        color: #000;
        text-align: left;
        background: #fff;
        border: 1px solid #e8e8e8;
        transition: background 0.3s ease;
        word-break: break-word;
        line-height: 18px;
      }
    }
  }
}
</style>
